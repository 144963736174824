import React from 'react';
import classes from './EndChatModal.module.scss';
import Modal from '../Modal/Modal';
import { disconnect } from '../../api/Connect/Connect';
import { setQuickResponses } from '../../store/actions/messages';
import { setResetChat } from '../../store/actions/general';
import { disconnectGenesysSession } from '../../store/actions/genesys';
import { useDispatch, useSelector } from 'react-redux';

const EndChatModal = ({isOpen, closeHandler}) => {
    const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);

    const dispatch = useDispatch();

    const endChat = () => {
        dispatch(setQuickResponses([]));
        dispatch(setResetChat(false));
        dispatch(disconnectGenesysSession());

        window.sutherland_variables.chat_ended = true;
        
        disconnect({
            chatId, 
            alias, 
            secureKey,
            userId,
            chatEnded
        });
    }

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'}>
            <div className={classes.EndChatModalContainer}>
                <h2>End chat</h2>
                <div className={classes.EndChatModalContent}>
                    <div className={classes.Label}>Are you sure you want to end this chat session?</div>
                    <div className={classes.Buttons}>
                        <button className={classes.ButtonEnd} onClick={endChat}>End Chat</button>
                        <button className={classes.ButtonCancel} onClick={closeHandler}>Cancel</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EndChatModal;