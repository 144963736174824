import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import PrechatForm from '../PrechatForm/PrechatForm';
import MainContainer from '../MainContainer/MainContainer';
import ChatLauncher from '../../components/ChatLauncher/ChatLauncher';
import { setMuiFontLoaded, setWidgetIsOpen } from '../../store/actions/general';

function RootContainer() {
  const { prechatEnabled, prechatFormOpened, widgetIsOpen } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const Container = () => {
    if (prechatEnabled && prechatFormOpened) {
      return (
        <PrechatForm />
      )
    }

    return (
      <MainContainer />
    )
  }

  useEffect(() => {
    const font = new FontFaceObserver('Material Icons');

    dispatch(setMuiFontLoaded(false))
    font.load(null, 10000)
      .then(() => {
        dispatch(setMuiFontLoaded(true))
      })
      .catch((error) => {
        dispatch(setMuiFontLoaded(false))
      });


    if (window.location.href.toLowerCase().includes("plasma-donation")) {
       dispatch(setWidgetIsOpen(false));
    }
  }, [dispatch]);

  return useMemo(() => {
    return (
      <div>
        {widgetIsOpen ? (
          <Container />
        ) : null}
        <ChatLauncher />
      </div>
    );
  }, [widgetIsOpen])
}

export default RootContainer;
