/* eslint-disable no-undef */
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './DrawerMenu.module.scss';
import { disconnect } from '../../api/Connect/Connect';
import { setMessages, setQuickResponses, setMessagesLoaded, setMode } from '../../store/actions/messages';
import { setResetChat, setDrawerIsOpen, setPrechatStatus, setAlertIsOpen} from '../../store/actions/general';
import { resetGenesysSession, disconnectGenesysSession } from '../../store/actions/genesys';
import { resetPrechat } from '../../store/actions/prechat';
import PopOver from '../controls/PopOver/PopOver';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';


const DrawerMenu = () => {
  const { drawerIsOpen, muiFontLoaded } = useSelector(state => state.general);
  const { chatId, alias, secureKey, userId, chatEnded } = useSelector(state => state.genesys);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDrawerIsOpen(drawerIsOpen ? false : true));
  }, [dispatch, drawerIsOpen]);

  const handleClose = useCallback((e) => {
    if (isMounted.current) {
      dispatch(setDrawerIsOpen(false));
    }
  }, [dispatch]);

  const restartChat = useCallback(() => {
    window.sutherland_variables.restart_chat = true;
    dispatch(setDrawerIsOpen(false))
    dispatch(setMessages([]));
    dispatch(setMode('bot'));
    dispatch(setQuickResponses([]));
    dispatch(setResetChat(false));
    dispatch(setMessagesLoaded(false));
    dispatch(disconnectGenesysSession());
    dispatch(setAlertIsOpen(true));
    dispatch(setPrechatStatus(true));
    dispatch(resetPrechat()); 
    disconnect({
        chatId, 
        alias, 
        secureKey,
        userId,
        chatEnded
    }).then(() => {
      setTimeout(() => {
        dispatch(resetGenesysSession());
      }, 1500)
    });

  }, [dispatch, chatId, alias, secureKey, userId, chatEnded]);

    return (
      <div className={classes.DrawerMenu}>
        <div className={classes.DrawerMenuSVG} onClick={handleOpen}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 12C13.5 12.8041 12.8284 13.4559 12 13.4559C11.1716 13.4559 10.5 12.8041 10.5 12C10.5 11.1959 11.1716 10.5441 12 10.5441C12.8284 10.5441 13.5 11.1959 13.5 12ZM13.5 5.20588C13.5 6.00994 12.8284 6.66176 12 6.66176C11.1716 6.66176 10.5 6.00994 10.5 5.20588C10.5 4.40182 11.1716 3.75 12 3.75C12.8284 3.75 13.5 4.40182 13.5 5.20588ZM13.5 18.7941C13.5 19.5982 12.8284 20.25 12 20.25C11.1716 20.25 10.5 19.5982 10.5 18.7941C10.5 17.9901 11.1716 17.3382 12 17.3382C12.8284 17.3382 13.5 17.9901 13.5 18.7941Z" fill="white"
            style={{fill: window.sutherland_variables.ui.header_styles.icon_colors}}
            />
          </svg>
        </div>
        <PopOver close={handleClose} opened={drawerIsOpen}>
          <ul className={classes.MenuBox}>
            <li className={classes.MenuItem} onClick={restartChat}>
              <div className={classes.MenuItemIcon}>
                <MuiPlaceholder
                    isMuiFontLoaded={muiFontLoaded}
                    element={<span aria-hidden="true">restart_alt</span>}
                    width={0}
                    height={0}
                    backgroundColor={'transparent'} />
              </div>
              <div className="text">
                <Trans i18nKey="drawerMenu.restartChat">
                  Restart Chat
                </Trans>
              </div>
            </li>
          </ul>
        </PopOver>
      </div>
    );
};
export default DrawerMenu;